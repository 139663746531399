import { Provider as AccordionSideNavProvider } from "@10x/ui/src/Support/SideNav/AccordionSideNavContext";
import * as Sentry from "@sentry/browser";
import {
  Debug as DebugIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  RewriteFrames as RewriteFramesIntegration,
} from "@sentry/integrations";
import App from "next/app";
import Script from "next/script";

import type { StaticProps } from "../contexts/static-props";
import StaticPropsContext from "../contexts/static-props";

Sentry.init({
  allowUrls: [/https?:\/\/((www)\.)?10xgenomics\.com/],
  dist: typeof window === "undefined" ? "server" : "client",
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.VERCEL_ENV || process.env.NODE_ENV,
  integrations: [
    // Gets as much info as possible for the event
    new ExtraErrorDataIntegration(),
    // Logs errors to the console
    new DebugIntegration(),
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    new RewriteFramesIntegration({
      iteratee: (frame) => {
        frame.filename = frame.filename!.replace(
          process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR as string,
          "app:///",
        );
        frame.filename = frame.filename.replace(".next", "_next");
        return frame;
      },
    }),
  ],
  release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "unknown",
});

class Site extends App {
  render() {
    const { Component, pageProps } = this.props;
    return (
      <StaticPropsContext.Provider
        value={(pageProps as { staticProps: StaticProps }).staticProps}
      >
        <AccordionSideNavProvider>
          {/* Google Tag Manager: https://developers.google.com/tag-manager/quickstart */}
          {process.env.NODE_ENV === "production" && (
            <Script id={"google-analytics"} strategy={"afterInteractive"}>
              {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}');
            `}
            </Script>
          )}
          {/* Full story analytics */}
          {process.env.NODE_ENV === "production" ? (
            <Script async id={"full-story"}>
              {`
                function getCookie(key) {
                  try {
                    let cookie = {};
                    const cookies = document.cookie
                      .split(";")
                      .reduce(
                        (acc, cur) =>
                          Object.assign(acc, { [cur.split("=")[0].trim()]: cur.split("=")[1] }),
                        {}
                      )["cookieyes-consent"];
                    cookie = cookies
                      .split(",")
                      .reduce(
                        (obj, pair) => ((pair = pair.split(":")), (obj[pair[0]] = pair[1]), obj),
                        {}
                      );
                    return cookie[key];
                  } catch (e) {
                    return undefined;
                  }                  
                }

                function checkIfInSampleGroup() {
                  const sampleKey = "TXG_FS_SAMPLE";
                  const sampleDateKey = "TXG_FS_SAMPLE_DATE";
                  let fsSampleValue = window.localStorage.getItem(sampleKey);
                  let fsSampleDate = window.localStorage.getItem(sampleDateKey);
                  
                  if (
                    !fsSampleValue ||
                    !fsSampleDate ||
                    Date.now() - fsSampleDate > 1000 * 60 * 60 * 24
                  ) {
                    fsSampleValue = String(Math.random());
                    fsSampleDate = String(Date.now());
                    window.localStorage.setItem(sampleKey, fsSampleValue);
                    window.localStorage.setItem(sampleDateKey, fsSampleDate);
                  }
                  
                  const fsSampleRate = 0.25;
                  return parseFloat(fsSampleValue) < fsSampleRate;
                }
                
                if (getCookie("consent") == "yes" && getCookie("analytics") == "yes" && checkIfInSampleGroup()) {
                  window['_fs_host'] = 'fullstory.com';
                  window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
                  window['_fs_org'] = 'o-1S3YBG-na1';
                  window['_fs_namespace'] = 'FS';
  
                  !function(m,n,e,t,l,o,g,y){var s,f,a=function(h){
                  return!(h in m)||(m.console&&m.console.log&&m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].'),!1)}(e)
                  ;function j(b){var h,d=[];function k(){h&&(d.forEach((function(b){var d;try{d=b[h[0]]&&b[h[0]](h[1])}catch(h){return void(b[3]&&b[3](h))}
                  d&&d.then?d.then(b[2],b[3]):b[2]&&b[2](d)})),d.length=0)}function r(b){return function(d){h||(h=[b,d],k())}}return b(r(0),r(1)),{
                  then:function(b,h){return j((function(r,i){d.push([b,h,r,i]),k()}))}}}a&&(g=m[e]=function(){var b=function(b,d,k,r){function i(i,c){
                  h(b,d,k,i,c,r)}r=r||2;var c,u=/Async$/;return u.test(b)?(b=b.replace(u,""),"function"==typeof Promise?new Promise(i):j(i)):h(b,d,k,c,c,r)}
                  ;function h(h,d,k,r,i,c){return b._api?b._api(h,d,k,r,i,c):(b.q&&b.q.push([h,d,k,r,i,c]),null)}return b.q=[],b}(),y=function(b){function h(h){
                  "function"==typeof h[4]&&h[4](new Error(b))}var d=g.q;if(d){for(var k=0;k<d.length;k++)h(d[k]);d.length=0,d.push=h}},function(){
                  (o=n.createElement(t)).async=!0,o.crossOrigin="anonymous",o.src="https://"+l,o.onerror=function(){y("Error loading "+l)}
                  ;var b=n.getElementsByTagName(t)[0];b.parentNode.insertBefore(o,b)}(),function(){function b(){}function h(b,h,d){g(b,h,d,1)}function d(b,d,k){
                  h("setProperties",{type:b,properties:d},k)}function k(b,h){d("user",b,h)}function r(b,h,d){k({uid:b},d),h&&k(h,d)}g.identify=r,g.setUserVars=k,
                  g.identifyAccount=b,g.clearUserCookie=b,g.setVars=d,g.event=function(b,d,k){h("trackEvent",{name:b,properties:d},k)},g.anonymize=function(){r(!1)
                  },g.shutdown=function(){h("shutdown")},g.restart=function(){h("restart")},g.log=function(b,d){h("log",{level:b,msg:d})},g.consent=function(b){
                  h("setIdentity",{consent:!arguments.length||b})}}(),s="fetch",f="XMLHttpRequest",g._w={},g._w[f]=m[f],g._w[s]=m[s],m[s]&&(m[s]=function(){
                  return g._w[s].apply(this,arguments)}),g._v="2.0.0")}(window,document,window._fs_namespace,"script",window._fs_script); 
                }
            `}
            </Script>
          ) : null}
          <Component {...pageProps} />
        </AccordionSideNavProvider>
      </StaticPropsContext.Provider>
    );
  }
}

export default Site;
